/**
 * Represents the result of a validation operation.
 * @template T The type of the object being validated.
 */
type ValidationResult<T> = {
    valid: true;
    errors: null;
    value: T;
} | {
    valid: false;
    errors: Array<string>;
    value: null;
};

/**
 * Describes the expected types of the properties of an object.
 * @template K The type of the object whose properties are being described.
 */
type TypeDescriptor<K> = {
    [key in keyof K]: 'string' | 'number' | 'boolean' | 'object' | 'array' | 'function' | 'symbol';
};

/**
 * Provides validation utilities for options objects.
 */
class Options {
    /**
     * Validates an object against the provided requirements.
     * 
     * @template T The type of the object being validated.
     * @param {Object} options The options for validation.
     * @param {any} options.passed The object to validate.
     * @param {Array<keyof T>} [options.requiredProperties] The properties required in the object.
     * @param {Partial<T>} [options.defaults] Default values for missing properties.
     * @param {TypeDescriptor<T>} [options.types] Descriptors for the types of the properties.
     * @returns {ValidationResult<T>} The result of the validation.
     * 
     * @example
     * // Define an interface for the object to be validated
     * interface User {
     *   name: string;
     *   age: number;
     *   email?: string;
     * }
     * 
     * // Object to validate
     * const userObj = {
     *   name: "Alice",
     *   age: "25", // Note: age is incorrectly a string here
     *   email: "alice@example.com"
     * };
     * 
     * // Validate the object
     * const result = Options.validate<User>({
     *   passed: userObj,
     *   requiredProperties: ["name", "age"],
     *   defaults: { email: "default@example.com" },
     *   types: { name: 'string', age: 'number', email: 'string' }
     * });
     * 
     * console.log(result);
     * // Output will show that validation failed due to incorrect type for 'age'
     */
    static validate<T extends Record<string, any>>(options: {
        passed: any,
        requiredProperties?: Array<keyof T>,
        defaults?: Partial<T>,
        types?: TypeDescriptor<T>
    }): ValidationResult<T> {
        let errors: string[] = [];
        let valid = true;

        const { passed, requiredProperties, defaults, types } = options;

        const result = {
            ...defaults,
            ...passed
        };

        for (const requiredProperty of requiredProperties ?? []) {
            if (!result.hasOwnProperty(requiredProperty)) {
                errors.push(`Missing required property: ${requiredProperty.toString()}`);
                valid = false;
            } 
        }

        for (const [property, propertyType] of Object.entries(types ?? {})) {
            if (!this.isType(result[property], propertyType)) {
                errors.push(`Invalid type for property: ${property}, expected: ${propertyType}`);
                valid = false;
            }
        }

        return valid ? {
            valid,
            errors: null,
            value: result
        } : {
            valid,
            errors,
            value: null
        };
    }

    /**
     * Checks if a value matches the specified type.
     * @param {any} value The value to check.
     * @param {string} type The type to check against.
     * @returns {boolean} True if the value matches the type, false otherwise.
     */
    private static isType(value: any, type: string): boolean {
        if (type === 'array') return Array.isArray(value);
        if (type === 'object') return typeof value === 'object' && !Array.isArray(value);
        return typeof value === type;
    }
}
